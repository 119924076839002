import { Activity, MessageCircleMore } from '#node_modules/lucide-react'
import { NavLink, useLocation } from '@remix-run/react'
import React from 'react'
import { Button } from '#app/components/ui/button.tsx'

export function TopNavigation() {
	const { pathname } = useLocation()
	return (
		<ul className={'flex flex-col gap-3 md:flex-row md:items-center'}>
			<li>
				<Button
					asChild
					className={'w-full items-center justify-start'}
					variant={pathname === '/' ? 'default' : 'outline'}
				>
					<NavLink to={'/programs'}>
						<Activity className={'mr-2'} />
						Programs
					</NavLink>
				</Button>
			</li>
			<li>
				<Button
					asChild
					variant={'outline'}
					className={'w-full items-center justify-start'}
				>
					<a
						href={'https://m.me/jhinrik'}
						target={'_blank'}
						rel={'noreferrer noopener'}
					>
						<MessageCircleMore className={'mr-2'} />
						Contact Jan
					</a>
				</Button>
			</li>
		</ul>
	)
}
